<template>
  <div>
    <v-container>
      <page-title title="Approval Modifier"></page-title>

      <v-card class="mb-2">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab v-for="item in items" :key="item.tab">{{ item.tab }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <component
              :is="item.component"
              :source_document="item.source_document"
              :title="item.title"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import BeritaAcaraListData from './components/BeritaAcaraListData';

export default {
  components: {
    BeritaAcaraListData
  },
  data() {
    return {
      search: "",
      tab: null,
      items: [
        {
          tab: "Berita Acara",
          component: "BeritaAcaraListData",
          title: "Berita Acara",
          source_document: "Berita Acara",
        },
      ],
    };
  },

  computed: {},

  methods: {},

  mounted() {
    this.requiredLogin();

    this.modulePermission("approvalmodifier", "view", true);
  },
};
</script>
