<template>
  <div>
    <v-card>
      <v-card-title>Berita Acara</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div style="max-width: 200px">
              <v-text-field :loading="loading" v-model="document_no_" @click:append="getData" flat outlined hide-details label="Doc No." placeholder="Doc. No." dense max-width="120px" append-icon="mdi-magnify"></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-center py-3" v-if="loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <v-alert color="error" dark v-if="!loading && results.length <= 0">Data Not Found</v-alert>

            <v-list class="mb-3" outlined v-if="!loading && results.length > 0">
              <v-list-item v-for="(item, i) in results" :key="i" style="border-bottom: 1px dotted #ddd">
                <v-list-item-avatar>
                  <v-img :src="item.user_img"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-row>
                    <v-col>
                      <div>
                        <v-list-item-title>{{ item.user_fullname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.user_position }}</v-list-item-subtitle>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    <v-btn text dense icon @click="viewDetail(item)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn text dense icon v-if="!item.approved" @click="viewUpdateApproval(item)" title="Edit Approval">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn text dense icon v-if="!item.approved" @click="deleteApproval(item)" title="Delete Approval">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-btn v-if="!loading && results.length > 0" color="primary" @click="viewAddApproval()" title="Add Approval">Add Approval</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-img :src="item.user_img" max-height="120" contain></v-img>
        <div class="text-center my-3">
          <span>{{ item.user_fullname }}</span
          ><br />
          <span class="text-caption">{{ item.user_position }}</span>
        </div>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Approval Level</v-list-item-title>
              <v-list-item-subtitle>{{ item.approval_level }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Approval Label</v-list-item-title>
              <v-list-item-subtitle>{{ item.approval_label }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Status Approval</v-list-item-title>
              <v-list-item-subtitle>{{ item.approved ? "Yes" : "No" }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Send Mail</v-list-item-title>
              <v-list-item-subtitle>{{ item.sendmail ? "Yes" : "No" }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Send Mail Date</v-list-item-title>
              <v-list-item-subtitle>{{ item.sendmail_date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn text dense icon v-if="!item.approved" @click="viewUpdateApproval(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="400">
      <v-card :loading="loading2">
        <v-card-title>Select User</v-card-title>
        <v-card-text>
          <v-autocomplete :items="founded_users" item-text="username" item-value="id" v-model="selected_user"></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="updateApproval()">Update</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog2 = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog3" max-width="400">
      <v-card :loading="loading3">
        <v-card-title>Select User</v-card-title>
        <v-card-text>
          <v-autocomplete :items="founded_users" item-text="username" label="User" item-value="id" v-model="add_item.user_id"></v-autocomplete>
          <v-text-field type="number" v-model="add_item.approval_level" label="Approval Level"></v-text-field>
          <v-text-field v-model="add_item.approval_label" label="Approval Label"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addApproval()">Submit</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog3 = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    loading2: false,
    loading3: false,
    loading_delete: false,
    dialog: null,
    dialog2: null,
    dialog3: null,
    document_no_: "",
    document: {},
    results: [],
    item: {},
    selected_user: 0,
    founded_users: [],
    search: "",
    add_item: {
      approval_level: 0,
      user_id: 0,
      approval_label: "",
    },
  }),

  methods: {
    async getData() {
      this.loading = true;
      var config = {
        params: {
          document_no_: this.document_no_,
        },
      };
      await this.$axios
        .get("approvalmodifier/beritaacara", config)
        .then((res) => {
          var resData = res.data.data;

          this.document = resData.document;
          this.results = resData.results;

          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
    async findUser() {
      this.loading2 = true;
      await this.$axios
        .get("approvalmodifier/find-user")
        .then((res) => {
          var resData = res.data.data;

          this.founded_users = resData.users;

          this.loading2 = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading2 = false;
        });
    },
    async updateApproval() {
      this.loading2 = true;
      var formData = new FormData();
      formData.append("id", this.item.id);
      formData.append("user_id", this.selected_user);

      await this.$axios
        .post("approvalmodifier/update-approval", formData)
        .then((res) => {
          var resData = res.data;
          this.showAlert(resData.status, resData.message);

          this.loading2 = false;
          this.selected_user = null;

          this.getData();
          this.dialog = false;
          this.dialog2 = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading2 = false;
        });
    },
    async deleteApproval(item) {
      this.loading_delete = true;
      var config = {
        params: {
          id: item.id,
        },
      };
      const del = async () => {
        await this.$axios
          .delete("approvalmodifier/delete-approval", config)
          .then((res) => {
            var resData = res.data.data;
            this.showAlert(res.data.status, res.data.message);

            this.document = resData.document;
            this.results = resData.results;

            this.loading_delete = false;
            this.getData();
          })
          .catch((error) => {
            this.axiosErrorHandler(error);
            this.loading_delete = false;
          });
      };

      this.showConfirm("Confirm", "Delete Approval?", del);
    },
    async addApproval() {
      this.loading2 = true;
      var formData = new FormData();
      formData.append("berita_acara_header_id", this.document.id);
      formData.append("user_id", this.add_item.user_id);
      formData.append("approval_label", this.add_item.approval_label);
      formData.append("approval_level", this.add_item.approval_level);

      await this.$axios
        .post("approvalmodifier/add-approval", formData)
        .then((res) => {
          var resData = res.data;
          this.showAlert(resData.status, resData.message);

          this.loading2 = false;
          this.selected_user = null;

          this.getData();
          this.dialog = false;
          this.dialog2 = false;
          this.dialog3 = false;
          this.add_item.approval_label = "";
          this.add_item.approval_level = 0;
          this.add_item.user_id = 0;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading2 = false;
        });
    },
    viewDetail(item) {
      this.item = item;
      this.dialog = true;
    },
    viewUpdateApproval(item) {
      this.item = item;
      this.dialog2 = true;
      this.findUser();
    },
    viewAddApproval() {
      this.add_item.approval_label = "";
      this.add_item.approval_level = 0;
      this.add_item.user_id = 0;

      this.dialog3 = true;
      this.findUser();
    },
  },
};
</script>
